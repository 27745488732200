export const dashboard = {
  date: {
    oclock: "Uhr",
  },
  general: {
    preview: "Neue Ansicht",
    email: "E-Mail-Adresse",
    save: "Speichern",
    ok: "Ok",
    cancel: "Abbrechen",
    language: {
      de: "Deutsch",
      en: "Englisch",
    },
    roles: {
      admin: "Admin",
      owner: "Admin",
      visitor: "Besucher*in",
      security: "Team-Leitung",
      securityTeam: "Team-Support",
      awareness: "Team-Leitung",
      awarenessTeam: "Team-Support",
      default: "Betrachter*in",
    },
    situation: {
      description: {
        unknown: "Etwas ist vorgefallen.",
        harassed: "Jemand wird belästigt.",
        attacked: "Jemand wird angegriffen.",
        somethingWrong: "Jemand fühlt sich unwohl.",
        emergency: "Es ist ein Notfall.",
        notify: "Jemand sollte mal vorbeikommen.",
        discriminated: "Jemand wird diskriminiert.",
      },
    },
    person: {
      description: {
        unknown: "Es gibt keine näheren Information.",
        victim: "Die Person ist selbst betroffen.",
        witness: "Die Person hat etwas beobachtet.",
        staff: "Jemand vom Personal hat etwas beobachtet.",
      },
    },
    error: {
      generic: "Irgendetwas ist schief gelaufen.",
      loginRequired: "Bitte logge dich erneut ein.",
    },
    form: {
      save: "Speichern",
    },
    booking: {
      free: "Kostenlos",
      payment: "Einmalige Veranstaltung",
      subscription: "Wiederkehrend",
    },
    humanTime: {
      justNow: "Gerade eben",
      oneMinuteAgo: "Vor einer Minute",
      minutesAgo: "Vor {{minutes}} Minuten",
      anHourAgo: "Vor einer Stunde",
      hoursAgo: "Vor {{hours}} Stunden",
      oneDayAgo: "Vor einem Tag",
      daysAgo: "Vor {{days}} Tagen",
      aWeekAgo: "Vor einer Woche",
      weeksAgo: "Vor {{weeks}} Wochen",
    },
  },
  barrier: {
    premium: {
      lock: "Diese Funktion ist nicht in der Basis Version verfügbar.",
    },
  },
  admin: {
    pages: {
      archive: {
        resetVenueArchived: "Reaktivieren",
      },
      map: {
        title: "Karte",
      },
      projects: {
        title: "Projekte",
        name: "Name",
        addManualBooking: "Manuelle Buchung",
        manualBookingModal: {
          bookingDate: "Buchungszeitraum",
          emptyBookingDate:
            "Aktuell ist kein Zeitraum eingestellt, in dem diese Veranstaltung stattfindet.",
        },
        createManualSubscription: "Erstellen",
        createManualPayment: "Erstellen",
        setVenueArchived: "Archivieren",
        archive: {
          title: "Archiviert",
        },
        overview: {
          title: "Übersicht",
        },
        map: {
          title: "Karte",
        },
        print: {
          title: "Druckvorlagen",
        },
      },
      statistics: {
        menu: "Statistiken",
        lastYear: "Letztes Jahr",
        last28Days: "Letzter Monat",
        lastWeek: "Letzte Woche",
        last24Hours: "Letzter Tag",
        location: "Ort",
        situation: "Situation",
        name: "Name",
      },
      events: {
        menu: "Logs",
      },
      booking: {
        title: "Buchungen",
        name: "Name",
        type: "Art",
        amount: "Betrag",
        status: "Status",
        date: "Von - bis",
      },
      user: {
        menu: "Nutzende",
        name: "Name",
        email: "E-Mail-Adresse",
        lastRefresh: "Letzter Login",
        createdAt: "Angemeldet",
        lastActivity: "Letzte Aktivität",
      },
    },
  },
  anonymous: {
    pages: {
      barrier: {
        hello: "Hallo!",
        hint: "Die Verwaltung von saferspaces ist im Moment nicht für kleine Bildschirmgrößen optimiert. Bitte wechsle zum Einrichten der QR Codes auf einen Laptop/Desktop PC oder kontaktiere uns unter <1>kontakt@saferspaces.io</1> falls du weitere Fragen hast.",
      },
    },
  },
  user: {
    pages: {
      booking: {
        active: "Aktiv",
        amount: "Betrag",
        payment: {
          title: "Zahlung",
          cancel: "Stornieren",
          canceled: "Storniert",
          cancelConfirmationTitle: "Zahlung stornieren",
          cancelConfirmationText: "Bist du sicher, dass du die Zahlung stornieren möchtest?",
          cancelConfirmation: "Jetzt stornieren",
          edit: "Bezahlmethode ändern",
          editPaymentMethodTitle: "Bezahlmethode ändern",
          editPaymentMethodText:
            "Haben sich deine Kartendaten geändert? Über den folgenden Link kannst du deine Bezahlmethode jederzeit ändern.",
          editPaymentMethod: "Zum Zahlungsanbieter",
        },
        subscription: {
          title: "Abo",
          cancel: "Kündigen",
          canceled: "Gekündigt",
          cancelConfirmationTitle: "Abo kündigen",
          cancelConfirmationText: "Bist du sicher, dass du das Abo kündigen möchtest?",
          cancelConfirmation: "Jetzt kündigen",
          edit: "Bezahlmethode ändern",
          editPaymentMethodTitle: "Bezahlmethode ändern",
          editPaymentMethodText:
            "Haben sich deine Kartendaten geändert? Über den folgenden Link kannst du deine Bezahlmethode jederzeit ändern.",
          editPaymentMethod: "Zum Zahlungsanbieter",
        },
        free: {
          title: "Testzugang",
          cancel: "cancel",
          canceled: "canceled",
          cancelConfirmationTitle: "cancelConfirmationTitle",
          cancelConfirmationText: "cancelConfirmationText",
          cancelConfirmation: "cancelConfirmation",
          edit: "edit",
          editPaymentMethodTitle: "editPaymentMethodTitle",
          editPaymentMethodText: "editPaymentMethodText",
          editPaymentMethod: "editPaymentMethod",
        },
        endsAt: "Endet",
        menu: "Buchungen",
        inactive: "Inaktiv",
        name: "Name",
        startsAt: "Startet",
        type: "Art",
      },
      projects: {
        agbInfos:
          "Du kannst saferspaces im eingeschränkten Umfang zwei Wochen lang kostenlos testen. Solltest du unsere Anwendung darüber hinaus nutzen wollen, kannst du hierfür eine Buchung abschließen.",
        agb: "Hiermit bestätige ich, die aktuellen <1>AGB</1> gelesen zu haben.",
        agbNext: "Zur Buchung",
        archivedAt: "Archiviert",
        prices:
          "Hiermit bestätige ich, die aktuelle <1>Preisliste</1> zur Kenntnis genommen zu haben.",
        activateProjectMonthlyCosts:
          "Es entstehen monatliche Kosten in Höhe von {{costs}} für die angegebene maximale Personenzahl von {{people}} Besuchenden.",
        activateProjectMonthlyCostsPre: "Es entstehen monatliche Kosten in Höhe von",
        activateProjectMonthlyCostsPost:
          "für die angegebene maximale Personenzahl von {{people}} Besuchenden.",
        activateProjectOneTimeCosts:
          "Es entstehen einmalige Kosten in Höhe von {{costs}} für die angegebene maximale Personenzahl von {{people}} Besuchenden.",
        activateProjectOneTimeCostsPre: "Es entstehen einmalige Kosten in Höhe von",
        activateProjectOneTimeCostsPost:
          "für die angegebene maximale Personenzahl von {{people}} Besuchenden.",
        activateEventCosts:
          "Es entstehen einmalige Kosten in Höhe von {{costs}} für die angegebene maximale Personenzahl von {{people}} Besuchenden.",
        activateEventSubmit: "Jetzt buchen",
        activateProject: "Jetzt buchen",
        activateProjectError:
          "Es gab einen Fehler bei der Buchung. Bitte wende dich direkt an uns.",
        activateProjectSubmit: "Jetzt buchen",
        activateSubscription: "Jetzt buchen",
        activateSubscriptionInfo: "Es entstehen monatliche Kosten in Höhe von",
        onBoardingPrice:
          "Zusätzlich fallen einmalige Gebühren in Höhe von {{costs}} an, die separat in Rechnung gestellt werden. Diese fallen nur bei deiner ersten Buchung an.",
        changeImage: "Bild ändern",
        changeName: "Umbenennen",
        createProject: "Hinzufügen",
        menu: "Projekte",
        name: "Name",
        newProject: "Neues Projekt",
        newProjectError: "Ein Fehler ist aufgetreten",
        next: "Weiter",
        noProject: "Willkommen in Deiner saferspaces Verwaltung!",
        noProjectEnd: "Dein saferspaces-Team",
        noProjectText:
          "Hier kannst du Deine Projekt*e erstellen und verwalten. Ein Projekt ist immer der Einsatzkontext in dem Du saferspaces nutzen bzw. anbieten wirst. Dein Club, Dein Festival das Stadion oder Stadtfest usw. Erstelle im ersten Schritt Dein erstes Projekt, im Anschluss kannst du direkt loslegen und die entsprechenden Codes für die unterschiedlichen Orte generieren. In der Verwaltung findest du zudem einige individuelle Einstellungen für die optimale Nutzung von saferspaces für Deinen Kontext. Schau Dich gerne um! Bei Fragen melde Dich jederzeit gern.",
        notVerified: "Testzugang",
        renameImageError: "Es gab einen Fehler beim Speichern",
        renameProject: "Projekt umbenennen",
        renameProjectError: "Es gab einen Fehler beim Speichern",
        editProjectDetails: "Bearbeiten",
        role: "Rolle",
        skip: "Kostenlos testen",
        submit: "Speichern",
        title: "Deine Projekte",
        updated: "Aktualisiert",
        venueImage: "URL",
        verify: "Bestätigen",
        subscription: {
          error: "Fehler",
          active: "Aktiv",
          inactive: "Inaktiv",
          finished: "Beendet",
          trialPeriod: "Testzugang",
          startsIn_zero: "Startet heute",
          startsIn_one: "Startet morgen",
          startsIn_other: "Startet in {{count}} Tagen",
          startsInMore: "Startet in mehr als 4 Wochen",
          endsIn_zero: "Endet heute",
          endsIn_one: "Endet morgen",
          endsIn_other: "Endet in {{count}} Tagen",
          endsInMore: "Endet in mehr als 4 Wochen",
          trialEndsIn_zero: "Kostenloser Testzeitraum endet heute",
          trialEndsIn_one: "Kostenloser Testzeitraum endet morgen",
          trialEndsIn_other: "Kostenloser Testzeitraum endet in {{count}} Tagen",
          freeTrialEndsIn_zero: "Kostenloser Testzeitraum endet heute",
          freeTrialEndsIn_one: "Kostenloser Testzeitraum endet morgen",
          freeTrialEndsIn_other: "Kostenloser Testzeitraum endet in {{count}} Tagen",
        },
        addProject: {
          name: "Name",
          type: "Art",
          weeklyOpen: "Regelmäßig geöffnet",
          capacity: "Kapazität",
          capacityEndAdornment: "Besuchende",
          startsAt: "Von",
          endsAt: "Bis",
        },
        ownerOnly:
          "Diese Funktion steht aktuell nur Nutzenden in der Rolle 'Besitzer*in' zur Verfügung",
      },
      profile: {
        title: "Dein Profil",
        menu: "Profil",
        settings: "Einstellungen",
        name: "Name",
        phoneNumber: "Telefonnummer",
        language: "Sprache",
      },
      payments: {
        menu: "Zahlungen",
      },
    },
  },
  venue: {
    name: "Name",
    actions: {
      archive: "Archive",
      edit: "Bearbeiten",
      bookNow: "Jetzt buchen",
      navigateToProject: "Zum Projekt",
    },
    type: {
      club: "Club",
      bar: "Bar",
      event: "Veranstaltung",
      festival: "Festival",
      other: "Sonstiges",
      stadium: "Stadion",
    },
    pages: {
      feedback: { title: "Feedback" },
      locations: {
        title: "Einstellungen",
      },
      settings: {
        title: "Konfigurator",
      },
      team: {
        title: "Team",
      },
      print: {
        title: "Druckmaterialien",
      },
      activity: {
        title: "Aktivität",
      },
      bookings: {
        title: "Buchungen",
      },
    },
  },
} as const;
