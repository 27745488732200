export const venue = {
  team: {
    members: {
      title: "Mitarbeitende",
      text: "Hier kannst du deine Mitarbeitenden einladen. Je nach Rolle haben diese verschiedene Funktionen und Berechtigungen.",
    },
    invites: {
      title: "Einladungen",
      text: "Hier siehst du deine offenen Einladungen. Sobald diese angenommen wurden (durch erstmaligen Login in der App oder Verwaltung), werden sie in deinem Team angezeigt. Du kannst Einladungen jederzeit zurückziehen. Sollte sich eine Person aus deinem Team nicht anmelden können, vergewissere dich, dass die E-Mail Adresse richtig geschrieben ist.",
      textOwner:
        "Hat Zugang zur Verwaltung und kann hier QR Codes erstellen und Einstellungen ändern. In der App können QR Codes zugewiesen, Push Notifications empfangen und Vorfälle betreut werden.",
      textAwareness:
        "In der App können QR Codes zugewiesen, Push Notifications empfangen und Vorfälle betreut werden.",
      textAwarenessTeam:
        "In der App können QR Codes zugewiesen und aktuelle Fälle eingesehen werden.",
      revoke: "Zurückziehen",
      button: "Einladen",
      modal: {
        title: "Einladen",
        name: "Name",
        nameError: "Bitte gib einen Namen ein.",
        email: "E-Mail",
        emailError: "Bitte gib eine gültige E-Mail Adresse ein.",
        role: "Rolle",
        invite: "Abschicken",
      },
    },
    securityHint:
      "Du hast aktuell noch niemanden in der Rolle als 'Awareness Leitung' in deinem Team. Nur diese erhalten Push-Notifications über eingehende Fälle in der App.",
    editPermission: {
      roleSuccess: "Rolle erfolgreich geändert",
    },
  },
  print: {
    template: "Druckvorlagen",
    format: "Format",
    description:
      "In diesem Bereich geht es um die Erstellung der Druckmaterialien. Es gibt zwei Varianten:",
    descriptionWithPosition:
      "Zum einen die ortsbasierten QR-Codes, die sich beispielsweise auf Plakaten oder Stickern befinden. Diese werden beim Anbringen über die App mit einem Standort verknüpft, sodass das Team bei einer Meldung sofort weiß, von wo der QR-Code gescannt wurde und wo die Hilfe benötigt wird.",
    descriptionWithoutPosition:
      "Zum anderen gibt es den ortsunabhängigen Code, der beispielsweise für Handkarten, Visitenkarten oder zum Teilen in den sozialen Netzwerken verwendet werden kann. Hinter dem ortsunabhängigen Code verbirgt sich euer Venue-Link. Geht eine Meldung über den Venue-Link ein, sieht das Team nicht, von wo sich die entsprechende Person meldet. Der Standort kann jedoch direkt im Chat geteilt werden.",
    positionBasedQRCodesTitle: "Ortsbasierte QR Codes",
    breadcrumbNavigation: {
      all: "Alle",
      position: "Ortsbasierte QR Codes",
      venue: "Ortsunabhängiger QR Code",
      sticker: "Sticker",
      poster: "Plakate",
      individual: "QR Codes",
      "venue-code": "Venue QR Code",
      "85x55mm": "Visitenkarten",
    },
    general: {
      saferspacesTemplate: "Saferspaces Vorlage",
      customTemplate: "Eigene Vorlage",
      uploadCustomTemplate: "Eigene Vorlage hochladen",
    },
    position: {
      title: "Ortsbasierte QR Codes",
      description:
        "Diesen QR Codes müssen vor oder beim Anbringen konkrete Positionen zu gewiesen werden. Da jeder QR Code mit einer Position verknüpft ist, müssen keine Standortdaten der scannenden Person erfasst werden.",
      lastCreatedPrintTemplate: "Zuletzt erstellt",
      noPrintTemplateCreated: "Keine Druckvorlagen vorhanden",
      createNew: "neu erstellen",
      createInProgress: "{{count}} {{format}} Vorlagen werden erstellt",
      createError: "Fehler beim Erstellen der Vorlagen",
      createNewCount: "Anzahl",
      downloadProgress: "Heruntergeladen",
      posters: {
        title: "Plakate",
        description:
          "Die Plakate sind der Hauptkontaktpunkt zur Kontakaufnahme für deine Besuchenden. Diese eignen sich insbesondere für feste Positionen und um auf das Konzept aufmerksam zu machen.",
        availableFormats: "A4 / A3 / A2",
        formats: {
          A2: "A2 Plakate",
          A3: "A3 Plakate",
          A4: "A4 Plakate",
        },
      },
      stickers: {
        title: "Sticker",
        description: "Eignen sich insbesondere für Rückzugsräume wie Toiletten oder Umkleiden.",
        availableFormats: "A6",
        formats: {
          A6: "Sticker",
        },
      },
    },
    venue: {
      description:
        "Über den QR Code können sich Personen ohne Weitergabe des Standorts an das Awareness Team wenden. Dieser eignet sich z.B. für mobile Teams, die unterwegs sind, oder Ordner*innen, die keinen festen Standort haben.",
      title: "Ortsunabhängiger QR Code",
      noteTitle: "Hinweis",
      noteText:
        "Nachdem du die entsprechende Vorlage ausgewählt hast, kannst du diese herunterladen und beliebig oft ausdrucken.",

      "85x55mm": {
        description:
          "Eignen sich insbesondere für mobile Teams, die unterwegs sind, oder Ordner*innen, die keinen festen Standort haben.",
        title: "Visitenkarten",
        availableFormats: "85 x 55 mm",
      },
      single: {
        title: "Venue QR Code",
        description: "Alleinstehender QR Code zum Drucken auf flexiblen Vorlagen.",
      },
    },
    settings: {
      background: "Hintergrund",
      bleed: "Beschnitt",
      cutContour: "Schnitt Kontur",
      cropMarks: "Schnittmarken",
      foreground: "Vordergrund",
      margin: "Rand",
    },
  },
  feedback: {
    allowFeedback: "Feedback erlauben",
    allowFeedbackDescription:
      "Möchtest du deinen Besuchenden die Möglichkeit geben, bei Inaktivität deines Ortes anonymes Feedback zu geben?",
    link: "Feedback Link",
    text: "Text",
    button: "Text auf dem Button",
    save: "Speichern",
    createFeedbackCode: "Feedback Code erstellen",
  },
  locations: {
    locations: {
      title: "Orte",
      description:
        "In diesem Bereich kannst du im ersten Schritt die Bereiche erstellen, in denen die QR-Codes platziert werden sollen. Beispiel: Keller, Erdgeschoss oder 1. OG. Die genaue Angabe des Standorts geschieht beim platzieren und verorten der Plakate vor Ort.",
    },
    map: {
      title: "Karte",
      description:
        "Hier kannst du den genauen Ort eurer Venue auswählen und über das Geofencing sicherstellen, dass sich nur Menschen an das Team wenden können, die sich im Bereich eurer Venue befinden. Sollte eine Person beispielsweise über den Venue-Link Kontakt aufnehmen und sich außerhalb eurer Fläche befinden, so könnt ihr dieser über das Anonyme Melde-tool dennoch eine Kontaktmöglichkeit bieten.",
    },
    title: "QR Codes",
    locationDescription:
      "Hier siehst du alle deine QR Codes. Du kannst sie bearbeiten, löschen oder neue hinzufügen.",
    positionDescription:
      "Die Positionen der einzelnen QR Codes können dann bei Bedarf noch genauer definiert werden. So kannst Du z.B. einen QR Code für den Eingangsbereich erstellen und einen weiteren für den Bereich vor der Bühne. Das kannst du entweder direkt beim Anlegen des Ortes machen oder später über die Zuweisung in der App.",
    bookingHint:
      "Mit dem Testzugang kannst du lediglich einen Ort anlegen. Später kannst du hier weitere hinzufügen.",
    createLocations: "Orte erstellen",
    noCodes: "Aktuell sind hier keine QR Codes zugewiesen.",
    move: "Verschieben",
    edit: "Bearbeiten",
    delete: "Löschen",
    numberOfCodes: "Anzahl der zugewiesenen QR Codes: {{count}}",
    geofencing: {
      save: "Speichern",
      latitude: "Breitengrad",
      longitude: "Längengrad",
      modal: {
        title: "Geo-Position",
        description: "Bitte gebe den Standort des Ortes an, oder nutze deine aktuelle Position.",
        requestingPosition: "Standort wird ermittelt",
      },
      requirePositionForVenue: {
        title: "Geofencing",
        description:
          "Mit aktiviertem Geofencing erlaubst du die Nutzung des Venue-Links nur innerhalb des von dir definierten Gebietes. Dies kann hilfreich sein, um deine Besuchenden wissen zu lassen, von wo aus sie Hilfe bekommen können.",
        venueLink: "Venue Link",
        venueLinkCopied: "Link kopiert",
        venueLinkDescription:
          "Der Venue-Link ist der Link, der die Besuchenden direkt in die Webapp leitet. Sofern das Geofencing aktiviert ist, wird im Vorfeld der Meldung einmal der Standort abgefragt, um zu sehen, ob sich die Person innerhalb oder außerhalb eurer Venue aufhält.",
      },
      requirePositionForQRCodes: {
        title: "Erweiterte Sicherheit",
        description:
          "Aktiviere diese Option nur dann, wenn du die Standortfreigabe auch für deine Plakate erfordern möchtest.",
      },
    },
    modal: {
      title: "QR Codes erstellen",
      locationDescription:
        "Lege jetzt los mit dem Erstellen der Orte. Ein <1>Ort</1> kann z.B. der erste Stock deines Clubs sein oder ein bestimmter Bereich deines Festivals. Den einzelnen Kontaktpunkten kannst du später noch genauere Bezeichnungen geben. Welche Orte möchtest du anlegen?",
      locationNamePlaceholder: "Name",
      locationBookingHint:
        "Mit dem Testzugang kannst du lediglich einen Ort anlegen. Später kannst du hier weitere hinzufügen.",
      positionDescription:
        "Überlege dir kurz, wo die QR Codes hängen sollen. Wieviele QR Codes willst du jeweils erstellen?",
      positionBookingHint: "Mit dem Testzugang kannst du hier jeweils nur einen Code anlegen.",
      guest: "QR Codes für Besuchende",
      staff: "QR Codes fürs Personal",
      back: "Zurück",
      create: "Erstellen",
      addLocation: "Weiteren Ort anlegen",
      next: "Weiter",
    },
    editLocationModal: {
      title: "Ort bearbeiten",
      locationNameLabel: "Name",
      saveButton: "Speichern",
      deleteButton: "Löschen",
      confirmDeleteDescription:
        "Bestätige die Löschung aller QR Codes für den Ort '{{location}}'. Diese Aktion kann nicht rückgängig gemacht werden.",
      confirmDeleteYes: "Ja, löschen",
    },
    deletePositionModal: {
      text: "Bitte bestätige die Löschung der ausgewählten QR-Codes. Vergewissere dich, dass diese nicht mehr an dem jeweiligen Standort hängen. Über die saferspaces App kannst du die QR Codes im nachhinein wieder erneut zuweisen.",
    },
    editPositionModal: {
      title: "QR Code Bearbeiten",
      shortcode: "Shortcode",
      positionNameLabel: "Name",
      saveButton: "Speichern",
      deleteButton: "Löschen",
      confirmDeleteDescription:
        "Bestätige die Löschung des QR Codes '{{position}}'. Diese Aktion kann nicht rückgängig gemacht werden.",
      confirmDeleteYes: "Ja, löschen",
    },
  },
  settings: {
    title: "Einstellungen",
    featureType: {
      free: "Diese Funktion ist in der kostenlosen Version verfügbar",
      basic: "Diese Funktion ist in der Basis Version verfügbar",
      premium: "Diese Funktion ist in der Premium Version verfügbar",
    },
    texts: {
      title: "Texte",
      description:
        "Indem du das entsprechende Textfeld editierst, siehst du rechts die Besuchendenansicht in der App. ",
    },
    qrCodes: {
      title: "QR Code Einstellungen",
      description:
        "Hier kannst du die individuellen Einstellungen und Texte für deinen Einsatzort bearbeiten. Mit den Screen-Ansichten kannst du genau sehen, welche Informationen den hilfesuchenden Personen angezeigt werden. <1>Achtung:</1> Alle Einstellungen werden direkt in die Ansicht der Besuchenden übertragen.",
      questionTitle: "Fragen anzeigen",
      questionText:
        "Legt fest, ob nach dem Scan des QR Codes Fragen zur Einordnung der Situation angezeigt werden sollen.",
      chatTitle: "Chat ermöglichen",
      chatText: "Legt fest, ob nach dem Scan des QR Codes ein Chat ermöglicht werden soll.",
      positionTitle: "Aktuelle Position teilen erlauben",
      positionText: "Ermöglicht es den Besuchenden ihre aktuelle Position zu teilen.",
      activeTitle: "Aktiv/inaktiv schalten",
      visitorTitle: "QR Codes für Besuchende",
      staffTitle: "QR Codes für Mitarbeitende",
      showQuestions: "Fragen anzeigen",
      hasChat: "Chat ermöglichen",
      enablePositionSharing: "Aktuelle Position teilen erlauben",
      contactNumber: "Notfallnummer",
      contactNumberText:
        "Wenn eine Telefonnummer hinterlegt ist, können Besuchende diese direkt anrufen.",
      activeIsRequired: "Begrenzte Verfügbarkeit",
      activeIsRequiredText:
        "Die begrenzte Verfügbarkeit solltest du aktivieren, wenn die Plakate auch dann für Besuchende zugänglich sind, wenn kein Team im Einsatz ist.",
      waitingTime: {
        title: "Reaktionszeit",
        text: "Legt fest wie lange es dauert, bis ein Vorfall als 'nicht angenommen' gilt. Nach dieser Zeit wird die Person in den Chat geleitet und eine automatische Nachricht wird gesendet. Danach kannst du immer noch auf den Vorfall reagieren.",
        minutes: "Minuten",
        oneMinute: "Eine Minute",
        seconds: "Sekunden",
        and: "und",
      },
      isActiveTitle: "Aktiv/inaktiv schalten",
      deactivateQRCodes: "QR Codes inaktiv schalten",
      activateQRCodes: "QR Codes aktiv schalten",
      isActiveText:
        "Deine QR Codes können entweder hier oder über die App aktiviert bzw. deaktiviert werden.",
      deactivate: "Inaktiv schalten",
      deactivateText:
        "Deine Codes werden inaktiv geschaltet. Damit können deine Besuchenden keine direkte Hilfe mehr anfordern.",
      activate: "Aktiv schalten",
      activateText:
        "Deine Codes werden aktiv geschaltet. Stelle sicher, dass jemand vor Ort im Einsatz ist, oder dass niemand außer dir Zugang zu den QR Codes hat.",
      situation: {
        current: "Aktuelle Auswahl",
        text: "Je nach Kontext kommen unterschiedliche Vorfälle vermehrt vor. An dieser Stelle kannst du zwei Optionen als Schwerpunkte wählen, welche neben dem „einen anderen Vorfall“ Button  in der Anwendung angezeigt werden.",
        more: "Mögliche weitere Optionen",
        attacked: "Angriff",
        discriminated: "Diskriminierung",
        emergency: "Medizinischer Notfall",
        harassed: "Belästigung",
        somethingWrong: "Etwas anderes",
        unknown: "Unbekannt",
        notify: "Unbekannt",
      },
    },
    directChat: { title: "Venue QR Code", label: "Aktivieren" },
    theme: {
      texts: "Texte",
      automaticFirstMessage: "Automatische Antwort",
      automaticFirstMessageHelperText: "Nachricht, die direkt nach Annahme des Chats gesendet wird",
      automaticUnavailableMessage: "Automatische Antwort",
      automaticUnavailableMessageHelperText:
        "Nachricht, die angezeigt wird, falls niemand nach der eingestellten Zeit reagiert.",
      waitingHeadline: "Überschrift",
      waitingHeadlineAccepted: "Überschrift",
      waitingHeadlineAcceptedHelperText: "Überschrift nachdem der Vorfall angenommen wurden",
      waitingHeadlineHelperText: "Überschrift direkt nach dem scannen des Codes",
      waitingMessage: "Text",
      waitingMessageAccepted: "Text",
      waitingMessageAcceptedHelperText: "Hinweis nachdem der Vorfall angenommen wurden",
      waitingMessageHelperText: "Hinweis direkt nach dem scannen des Codes",
      images: "Bilder",
      imagesLogo: "Logo",
      imagesBackground: "Hintergrund",
    },
    security: "Sicherheit",
    securityGeoFencing: "Geofencing aktivieren",
    notifications: "Benachrichtigungen",
    notificationsNew: "Neue Meldungen",
  },
  activity: {
    title: "Aktivität",
    current: "Aktuell",
    description:
      "Hier siehst du eine Liste der eingegangenen Meldungen. Wir achten strikt darauf, keine personenbezogenen Daten zu erfassen. Dennoch liefern die gelisteten Daten wertvolle Informationen darüber, wann, was und an welchem Ort etwas vorgefallen ist.",
    noReports: "Es gibt keine aktuellen Meldungen",
  },
} as const;
